import { USER_DATA_INTER } from '../user/constant';

export const MODULE_NAME = 'AUTH_MODULE_NAME';

export const ACTION_TYPE = {
  SET_DATA: `${MODULE_NAME}.SET_DATA`,
  SET_META_DATA: `${MODULE_NAME}.SET_META_DATA`,
  CLEAR_DATA: `${MODULE_NAME}.CLEAR_DATA`,
  SET_SAFETY_DATA: `${MODULE_NAME}.SET_SAFETY_DATA`,
};

export interface META_DATA_INTER {
  haveLogin?: boolean;
  haveWellcome?: boolean;
  notificationToken?: string;
  activeWallet?: {
    id?: number;
    amount?: number;
  };
}

export interface ACTION_DATA_INTER {
  user: USER_DATA_INTER;
  meta: META_DATA_INTER;
}

export interface SAFETY_INTER {
  pincodeActive?: boolean;
  biometricActive?: boolean;
  twoFactorActive?: boolean;
  twoFactorAccess?: boolean;
  biometricAccess?: boolean;
  pincodeAccess?: boolean;
  access?: boolean;
}

export interface STORE_INTER {
  logged: boolean | null;
  isConfirm: boolean | null;
  meta: META_DATA_INTER | null;
  user: USER_DATA_INTER | null;
  safety: SAFETY_INTER;
}

export interface LOCAL_STORE_INTER {
  token: string | null;
  sessionToken?: string | null;
  user: USER_DATA_INTER | null;
  meta: META_DATA_INTER | null;
}

export const API = {
  GET_TOKEN: {
    TYPE: 'GET',
    URL: '/auth/token',
  },
  GET_SAFETY: {
    TYPE: 'GET',
    URL: '/auth/safety',
  },
};

export interface GET_TOKEN_ACTION_RESPONSE_INTER {
  accessToken: string;
}

export enum AUTH_TYPE {
  PHONE = 1,
  EMAIL = 2,
}
