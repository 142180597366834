import React from 'react';

import { TextElem } from '../../common/text';
import styled from 'styled-components';

import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_ENUM } from '../../theme/size';
import { Spacing } from '../../theme';
import bg from './bg.png';
import { GridElem } from '../../common/grid';
import { THEME_ENUM } from '../../data/theme/constant';
import { COLOR_ENUM } from '../../theme/color';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from '../../lib/store';
import logoIcon from '../../asset/svg/logo.svg';
import logoLightIcon from '../../asset/svg/logo-light.svg';

export const Component: React.FC<{}> = () => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  return (
    <Content>
      <Image src={bg} />
      <Video autoPlay muted loop id="myVideo" poster={bg}>
        <source src={require('./opt1.mp4')} type="video/mp4"></source>
      </Video>

      <Wellcome spacing={4}>
        <Logo src={logoIcon} />
        <Text>
          <TextElem tid="AUTH.WELLCOME" size="main" color="white" />
        </Text>
      </Wellcome>
    </Content>
  );
};

const Wellcome = styled(GridElem)`
  justify-content: center;
  justify-items: center;
  padding: 0 ${Spacing(2)};
  margin: auto;
  max-width: 370px;
  width: 100%;
`;

const Text = styled.div`
  color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  display: inline !important;
  text-align: center;
  margin: auto;
  span {
    line-height: 150%;
  }
`;

const Logo = styled.img`
  height: 36px;
`;

const Image = styled.img`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -100;
`;

const Video = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -50;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px;
`;
