import React from 'react';
import { IonApp, IonRouterOutlet, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router';
import { SettingsPage, SETTINGS_PAGE_PATH } from '../settings';

import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

import {
  AUTH_RECOVERY_CONFIRM_PAGE_PATH,
  AuthRecoveryConfirmPage,
} from '../auth-recovery-confirm';

import {
  AUTH_RECOVERY_PASSWORD_PAGE_PATH,
  AuthRecoveryPasswordPage,
} from '../auth-recovery-password';

import { STORE_INTER } from '../../data/auth/constant';

import { AUTH_LOGIN_PAGE_PATH, AuthLoginPage } from '../auth-login';
import { AUTH_RECOVERY_PAGE_PATH, AuthRecoveryPage } from '../auth-recovery';
import {
  WEBINAR_CREATE_PAGE_PATH,
  WebinarCreatePage,
} from '../webinar-item-create';
import { WEBINAR_PAGE_PATH, WebinarPage } from '../webinar';
import {
  WEBINAR_SETTINGS_PAGE_PATH,
  WebinarSettingsPage,
} from '../webinar-settings';
import { SESSION_ITEM_PAGE_PATH, SessionItemPage } from '../session-item-data';
import {
  WEBINAR_ITEM_IMPORT_PAGE_PATH,
  WebinarItemImportPage,
} from '../webinar-item-import';
import {
  WEBINAR_SESSION_PAGE_PATH,
  WebinarSessionPage,
} from '../webinar-session';
import { ADMIN_FILE_PAGE_PATH, AdminFilePage } from '../admin-file';
import { ADMIN_PROJECT_PAGE_PATH, AdminProjectPage } from '../admin-project';
import { ADMIN_USER_PAGE_PATH, AdminUserPage } from '../admin-user';
import { USER_ROLE } from '../../data/user/constant';
import {
  SESSION_ITEM_ROOM_PAGE_PATH,
  SessionItemRoomPage,
} from '../session-item-room';
import { SESSION_VIEWER_PAGE_PATH, SessionViewerPage } from '../session-viewer';
import { SESSION_PERSON_PAGE_PATH, SessionPersonPage } from '../session-person';
import { SESSION_EVENT_PAGE_PATH, SessionEventPage } from '../session-event';
import {
  SESSION_ITEM_IMPORT_PAGE_PATH,
  SessionItemImportPage,
} from '../session-item-import';
import {
  SESSION_ITEM_OVER_PAGE_PATH,
  SessionItemOverPage,
} from '../session-item-over';
import {
  EXPENDITURE_CREATE_PAGE_PATH,
  ExpenditureCreatePage,
} from '../expenditure-item-create';
import {
  EXPENDITURE_LIST_PAGE_PATH,
  ExpenditureListPage,
} from '../expenditure-item';
import { WELLCOME_PAGE_PATH, WellcomePage } from '../wellcome';
import {
  WEBINAR_PROFILE_PAGE_PATH,
  WebinarProfilePage,
} from '../webinar-profile';
import {
  SESSION_ITEM_ROOM_MODERATION_PAGE_PATH,
  SessionItemRoomModerationPage,
} from '../session-item-room-moderation';

export const Page: React.FC = () => {
  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  const isLogged = () => state.logged;

  const isAdmin = () => {
    return state?.user?.role === USER_ROLE.ADMIN;
  };

  const isManager = () => {
    return state?.user?.role === USER_ROLE.MANAGER;
  };

  const isAccountant = () => {
    return state?.user?.role === USER_ROLE.ACCOUNTANT;
  };

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          {isLogged() ? (
            <Switch>
              {/* <Route path={SETTINGS_PAGE_PATH} component={SettingsPage} exact />

              {isManager() && (
                <Route
                  path={EXPENDITURE_CREATE_PAGE_PATH}
                  component={ExpenditureCreatePage}
                  exact
                />
              )}
              {isManager() && <Redirect to={EXPENDITURE_CREATE_PAGE_PATH} />}
              {isAccountant() && (
                <Route
                  path={EXPENDITURE_LIST_PAGE_PATH}
                  component={ExpenditureListPage}
                  exact
                />
              )}
              {isAccountant() && <Redirect to={EXPENDITURE_LIST_PAGE_PATH} />}

              <Route
                path={SESSION_ITEM_ROOM_MODERATION_PAGE_PATH}
                component={SessionItemRoomModerationPage}
                exact
              />

              <Route
                path={WEBINAR_CREATE_PAGE_PATH}
                component={WebinarCreatePage}
                exact
              />
              <Route
                path={WEBINAR_ITEM_IMPORT_PAGE_PATH}
                component={WebinarItemImportPage}
                exact
              />

              <Route
                path={SESSION_ITEM_IMPORT_PAGE_PATH}
                component={SessionItemImportPage}
                exact
              />
              <Route
                path={WEBINAR_SETTINGS_PAGE_PATH}
                component={WebinarSettingsPage}
                exact
              />

              <Route
                path={WEBINAR_PROFILE_PAGE_PATH}
                component={WebinarProfilePage}
                exact
              />

              <Route
                path={WEBINAR_SESSION_PAGE_PATH}
                component={WebinarSessionPage}
                exact
              />
              <Route
                path={SESSION_VIEWER_PAGE_PATH}
                component={SessionViewerPage}
                exact
              />
              <Route
                path={SESSION_PERSON_PAGE_PATH}
                component={SessionPersonPage}
                exact
              />
              <Route
                path={SESSION_EVENT_PAGE_PATH}
                component={SessionEventPage}
                exact
              /> */}
              <Route path={WELLCOME_PAGE_PATH} component={WellcomePage} exact />

              {/* <Route
                path={SESSION_ITEM_PAGE_PATH}
                component={SessionItemPage}
                exact
              />
              <Route
                path={SESSION_ITEM_OVER_PAGE_PATH}
                component={SessionItemOverPage}
                exact
              />
              <Route path={WEBINAR_PAGE_PATH} component={WebinarPage} exact />
              {isAdmin() && (
                <Route
                  path={ADMIN_PROJECT_PAGE_PATH}
                  component={AdminProjectPage}
                  exact
                />
              )}

              {isAdmin() && (
                <Route
                  path={ADMIN_USER_PAGE_PATH}
                  component={AdminUserPage}
                  exact
                />
              )}

              {isAdmin() && (
                <Route
                  path={ADMIN_FILE_PAGE_PATH}
                  component={AdminFilePage}
                  exact
                />
              )}

              <Route
                path={SESSION_ITEM_ROOM_PAGE_PATH}
                component={SessionItemRoomPage}
                exact
              />

              <Redirect to={WEBINAR_PAGE_PATH} /> */}
              <Redirect to={WELLCOME_PAGE_PATH} />
            </Switch>
          ) : (
            <Switch>
              {/* <Route
                path={AUTH_RECOVERY_CONFIRM_PAGE_PATH}
                component={AuthRecoveryConfirmPage}
                exact
              />
              <Route
                path={AUTH_RECOVERY_PASSWORD_PAGE_PATH}
                component={AuthRecoveryPasswordPage}
                exact
              />
              <Route
                path={AUTH_RECOVERY_PAGE_PATH}
                component={AuthRecoveryPage}
                exact
              />
              <Route
                path={AUTH_LOGIN_PAGE_PATH}
                component={AuthLoginPage}
                exact
              />
              <Route
                path={SESSION_ITEM_PAGE_PATH}
                component={SessionItemPage}
                exact
              />

              <Route
                path={SESSION_ITEM_ROOM_PAGE_PATH}
                component={SessionItemRoomPage}
                exact
              />
              <Route
                path={SESSION_ITEM_OVER_PAGE_PATH}
                component={SessionItemOverPage}
                exact
              /> */}
              <Route path={WELLCOME_PAGE_PATH} component={WellcomePage} exact />

              <Redirect to={WELLCOME_PAGE_PATH} />
            </Switch>
          )}
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};
