import { ACTION_DATA_INTER, ACTION_TYPE, STORE_INTER } from './constant';
import { ActionData } from '../../lib/store/core';

const initialState: STORE_INTER = {
  logged: null,
  isConfirm: null,
  safety: {
    twoFactorActive: false,
    biometricActive: false,
    pincodeActive: false,
    access: false,
    pincodeAccess: false,
    twoFactorAccess: false,
    biometricAccess: false,
  },
  user: null,
  meta: {
    notificationToken: '',
    activeWallet: { id: undefined, amount: undefined },
  },
};

export function Store(
  state = initialState,
  action: ActionData<ACTION_DATA_INTER>,
) {
  switch (action.type) {
    case ACTION_TYPE.SET_DATA:
      return {
        ...state,
        logged: !!action.data.user,
        isConfirm: action.data.user?.confirmAccount,
        user: action.data.user,
        meta: action.data.meta,
      };
    case ACTION_TYPE.SET_META_DATA:
      return {
        ...state,
        meta: action.data,
      };

    case ACTION_TYPE.SET_SAFETY_DATA:
      return {
        ...state,
        safety: action.data,
      };

    case ACTION_TYPE.CLEAR_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
