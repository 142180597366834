import React, { ReactNode } from 'react';
import { IonContent } from '@ionic/react';

import styled, { css } from 'styled-components';

import { COLOR_ENUM, COLOR_TYPE, LIGHT_COLOR_DATA } from '../../theme/color';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from 'react-redux';
import { THEME_ENUM } from '../../data/theme/constant';
import backgroundImg from '../../asset/img/background.png';
import { Spacing } from '../../theme';

export const Elem: React.FC<{
  children: ReactNode;
  background?: COLOR_TYPE;
  className?: string;
  oneColumn?: boolean;
  tab?: boolean;
  style?: object;
}> = ({
  children,
  background = 'backgroundPrimary',
  className,
  oneColumn = false,
  tab = false,
  style,
}) => {
  const { state } = useSelector((s: any) => ({
    state: s[THEME_MODULE_NAME],
  }));

  const bg =
    state.type === THEME_ENUM.LIGHT
      ? LIGHT_COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]
      : `linear-gradient(270deg, rgba(21, 19, 28, 0.9) 0%, rgba(17, 17, 29, 0) 100%, rgba(21, 19, 28, 0) 100%),
  url(${backgroundImg}) center/cover no-repeat , #15131C`;
  return (
    <Background
      background={bg}
      className={className}
      oneColumn={oneColumn}
      tab={tab}
      id="layoutApp1"
      style={style}
    >
      {children}
    </Background>
  );
};

const Background = styled(IonContent)<{
  background?: string;
  oneColumn?: boolean;
  tab: boolean;
}>`
  width: 100%;
  height: 100%;

  ${({ background }) => {
    return css`
      /* background-size: cover; */
      --background: ${background};
    `;
  }}
  .ant-popover-inner {
    padding: ${Spacing(4)};
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  }
  z-index: 9999999999999999999;
  .ant-popover-arrow {
    ::before {
      background-color: ${({ theme }) =>
        theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
    }
  }
  .ant-popover {
    border-radius: 10px;
  }
  ion-skeleton-text {
    background: ${({ theme }) => theme[COLOR_ENUM.TEXT_THIRD]} !important;
    opacity: 0.6;
  }
`;
