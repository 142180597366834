import React from 'react';
import { RouteComponentProps } from 'react-router';
import { WellcomeContainer } from '../../epic/wellcome';

import { PageElem } from '../../common/page';
import { LayoutAppElem } from '../../common/layout-app';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <WellcomeContainer />
      </LayoutAppElem>
    </PageElem>
  );
};
